import React from "react"
import Layout from "../components/layout"

const ServicesPage = () => (
  <Layout title="What we do">
    <p>Siteware has a broad range of expertise in numerous technologies. We have experience in following areas:</p>
    <ul>
      <li>Enterprise applications</li>
      <li>Geospatial applications</li>
      <li>Websites and e-commerce applications</li>
      <li>Mobile applications and progressive web apps</li>
      <li>Game development</li>
      <li>Iot applications</li>
    </ul>
    <p>We love to learn and explore new technologies so we are always evolving.</p>
    <p>Do you have an idea or plan for a new application and need a partner with a proven track record to 
implement that application. We are here to help you. </p>
    <p>We can help you create the application from A to Z. This usually involves following tasks:</p>
      <ul>
        <li>Architecture and technical analysis</li>
        <li>Development cost estimation</li>
        <li>Staffing</li>
        <li>Day-to-day technical lead &amp; development</li>
        <li>Automation of the deploy cycle of the application (DevOps)</li>
      </ul>
    <p>When developing we love to work in an agile way and communicate lots along the way.<br/>As a result all 
      parties are always on the same page. This ensures that feedback loops are small and development can 
      quickly be steered in the right way when new insights are gained.</p>

  </Layout>
)

export default ServicesPage
